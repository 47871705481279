var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(function (root, factory) {
  // Node. Does not work with strict CommonJS, but
  // only CommonJS-like enviroments that support module.exports,
  // like Node.
  exports = factory();
})(window, function () {
  function extend(specific, general) {
    var obj = {};

    for (var key in general) {
      obj[key] = key in specific ? specific[key] : general[key];
    }

    return obj;
  }

  function Sparkline(element, options) {
    (this || _global).element = element;
    (this || _global).options = extend(options || {}, Sparkline.options);

    init: {
      (this || _global).element.innerHTML = "<canvas></canvas>";
      (this || _global).canvas = (this || _global).element.firstChild;
      (this || _global).context = (this || _global).canvas.getContext("2d");
      (this || _global).ratio = window.devicePixelRatio || 1;

      if ((this || _global).options.tooltip) {
        (this || _global).canvas.style.position = "relative";
        (this || _global).canvas.onmousemove = showTooltip.bind(this || _global);
      }
    }
  }

  Sparkline.options = {
    width: 100,
    height: null,
    lineColor: "black",
    lineWidth: 1.5,
    startColor: "transparent",
    endColor: "black",
    maxColor: "transparent",
    minColor: "transparent",
    minValue: null,
    maxValue: null,
    minMaxValue: null,
    maxMinValue: null,
    dotRadius: 2.5,
    tooltip: null,
    fillBelow: true,
    fillLighten: 0.5,
    startLine: false,
    endLine: false,
    minLine: false,
    maxLine: false,
    bottomLine: false,
    topLine: false,
    averageLine: false
  };

  Sparkline.init = function (element, options) {
    return new Sparkline(element, options);
  };

  Sparkline.draw = function (element, points, options) {
    var sparkline = new Sparkline(element, options);
    sparkline.draw(points);
    return sparkline;
  };

  function getY(minValue, maxValue, offsetY, height, index) {
    var range = maxValue - minValue;

    if (range == 0) {
      return offsetY + height / 2;
    } else {
      return offsetY + height - ((this || _global)[index] - minValue) / range * height;
    }
  }

  function drawDot(radius, x1, x2, color, line, x, y) {
    (this || _global).context.beginPath();

    (this || _global).context.fillStyle = color;

    (this || _global).context.arc(x, y, radius, 0, Math.PI * 2, false);

    (this || _global).context.fill();

    drawLine.call(this || _global, x1, x2, line, x, y);
  }

  function drawLine(x1, x2, style, x, y) {
    if (!style) return;

    (this || _global).context.save();

    (this || _global).context.strokeStyle = style.color || "black";
    (this || _global).context.lineWidth = (style.width || 1) * (this || _global).ratio;
    (this || _global).context.globalAlpha = style.alpha || 1;

    (this || _global).context.beginPath();

    (this || _global).context.moveTo(style.direction != "right" ? x1 : x, y);

    (this || _global).context.lineTo(style.direction != "left" ? x2 : x, y);

    (this || _global).context.stroke();

    (this || _global).context.restore();
  }

  function showTooltip(e) {
    var x = e.offsetX || e.layerX || 0;
    var delta = ((this || _global).options.width - (this || _global).options.dotRadius * 2) / ((this || _global).points.length - 1);
    var index = minmax(0, Math.round((x - (this || _global).options.dotRadius) / delta), (this || _global).points.length - 1);
    (this || _global).canvas.title = (this || _global).options.tooltip((this || _global).points[index], index, (this || _global).points);
  }

  Sparkline.prototype.draw = function (points) {
    points = points || [];
    (this || _global).points = points;
    (this || _global).canvas.width = (this || _global).options.width * (this || _global).ratio;
    (this || _global).canvas.style.width = (this || _global).options.width + "px";
    var pxHeight = (this || _global).options.height || (this || _global).element.offsetHeight;
    (this || _global).canvas.height = pxHeight * (this || _global).ratio;
    (this || _global).canvas.style.height = pxHeight + "px";
    var lineWidth = (this || _global).options.lineWidth * (this || _global).ratio;
    var offsetX = Math.max((this || _global).options.dotRadius * (this || _global).ratio, lineWidth / 2);
    var offsetY = Math.max((this || _global).options.dotRadius * (this || _global).ratio, lineWidth / 2);
    var width = (this || _global).canvas.width - offsetX * 2;
    var height = (this || _global).canvas.height - offsetY * 2;
    var minValue = Math.min.apply(Math, points);
    var maxValue = Math.max.apply(Math, points);
    var bottomValue = (this || _global).options.minValue != undefined ? (this || _global).options.minValue : Math.min(minValue, (this || _global).options.maxMinValue != undefined ? (this || _global).options.maxMinValue : minValue);
    var topValue = (this || _global).options.maxValue != undefined ? (this || _global).options.maxValue : Math.max(maxValue, (this || _global).options.minMaxValue != undefined ? (this || _global).options.minMaxValue : maxValue);
    var minX = offsetX;
    var maxX = offsetX;
    var x = offsetX;
    var y = getY.bind(points, bottomValue, topValue, offsetY, height);
    var delta = width / (points.length - 1);
    var dot = drawDot.bind(this || _global, (this || _global).options.dotRadius * (this || _global).ratio, offsetX, width + offsetX);
    var line = drawLine.bind(this || _global, offsetX, width + offsetX);

    (this || _global).context.save();

    (this || _global).context.strokeStyle = (this || _global).options.lineColor;
    (this || _global).context.fillStyle = (this || _global).options.lineColor;
    (this || _global).context.lineWidth = lineWidth;
    (this || _global).context.lineCap = "round";
    (this || _global).context.lineJoin = "round";

    if ((this || _global).options.fillBelow && points.length > 1) {
      (this || _global).context.save();

      (this || _global).context.beginPath();

      (this || _global).context.moveTo(x, y(0));

      for (var i = 1; i < points.length; i++) {
        x += delta;
        minX = points[i] == minValue ? x : minX;
        maxX = points[i] == maxValue ? x : maxX;

        (this || _global).context.lineTo(x, y(i));
      }

      (this || _global).context.lineTo(width + offsetX, height + offsetY + lineWidth / 2);

      (this || _global).context.lineTo(offsetX, height + offsetY + lineWidth / 2);

      (this || _global).context.fill();

      if ((this || _global).options.fillLighten > 0) {
        (this || _global).context.fillStyle = "white";
        (this || _global).context.globalAlpha = (this || _global).options.fillLighten;

        (this || _global).context.fill();

        (this || _global).context.globalAlpha = 1;
      } else if ((this || _global).options.fillLighten < 0) {
        (this || _global).context.fillStyle = "black";
        (this || _global).context.globalAlpha = -(this || _global).options.fillLighten;

        (this || _global).context.fill();
      }

      (this || _global).context.restore();
    }

    x = offsetX;

    (this || _global).context.beginPath();

    (this || _global).context.moveTo(x, y(0));

    for (var i = 1; i < points.length; i++) {
      x += delta;

      (this || _global).context.lineTo(x, y(i));
    }

    (this || _global).context.stroke();

    (this || _global).context.restore();

    line((this || _global).options.bottomLine, 0, offsetY);
    line((this || _global).options.topLine, 0, height + offsetY + lineWidth / 2);
    dot((this || _global).options.startColor, (this || _global).options.startLine, offsetX + (points.length == 1 ? width / 2 : 0), y(0));
    dot((this || _global).options.endColor, (this || _global).options.endLine, offsetX + (points.length == 1 ? width / 2 : width), y(points.length - 1));
    dot((this || _global).options.minColor, (this || _global).options.minLine, minX + (points.length == 1 ? width / 2 : 0), y(points.indexOf(minValue)));
    dot((this || _global).options.maxColor, (this || _global).options.maxLine, maxX + (points.length == 1 ? width / 2 : 0), y(points.indexOf(maxValue))); //line(this.options.averageLine, )
  };

  function minmax(a, b, c) {
    return Math.max(a, Math.min(b, c));
  }

  return Sparkline;
});

export default exports;